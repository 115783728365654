import './App.css';
import styled from 'styled-components';
import { Typography, Divider } from 'antd';
import 'antd/dist/reset.css';

// Styled component for the wrapper
const Wrapper = styled.div`
  max-width: 1600px;
  padding: 16px;
  margin: 0 auto;
`;

const MyFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
`;

const Footer = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

function App() {
  return (
    <Wrapper>
      <Typography.Title level={1}>Nicholas Barbarino</Typography.Title>
      <Divider />
      <Typography.Title level={3}>Sarina's Legacy</Typography.Title>
      <Typography.Text>
        A heartfelt collection of poems that seek to capture the essence and
        legacy of the author's mother.
      </Typography.Text>
      <br />
      <Typography.Text>
        Poems by Nicholas Barbarino. Illustrations by Peta Minnici.
      </Typography.Text>
      <br />
      <Typography.Text>First printed in 2020.</Typography.Text>
      <MyFrame title="Sarina's Legacy" src="/sarina.html"></MyFrame>
      <Divider />
      <Typography.Title level={3}>The Illusion Poems</Typography.Title>
      <Typography.Text>
        A poetic exploration of life's significant milestones and quiet moments.
      </Typography.Text>
      <br />
      <Typography.Text>
        Poems by Nicholas Barbarino. Illustrations by Peta Minnici.
      </Typography.Text>
      <br />
      <Typography.Text>First printed in 2018.</Typography.Text>
      <MyFrame title="The Illusion Poems" src="/illusion.html"></MyFrame>

      <Divider />
      <Footer>
        <Typography.Text>
          © Nick Barbarino {new Date().getFullYear()}
        </Typography.Text>
      </Footer>
    </Wrapper>
  );
}

export default App;
